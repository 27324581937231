import CampIndex from './CampIndex'
import Geocode from 'react-geocode'
import ReactGA from 'react-ga4'
import moment from 'moment'
const algoliasearch = require('algoliasearch')
const Promise = require('bluebird')

let algoliaClient = algoliasearch('ZZA31KBCTO', 'cb9197f1b425eec6de49243879150d17', {
  timeout: 300 * 1000
})

let algoliaIndex = algoliaClient.initIndex('SimSum')

// Geocode.setApiKey('AIzaSyBNvXrkkjRats7l_xiBvWGLtKOnKjs5eGM')
// Geocode.setApiKey('AIzaSyBfh3LJJ17eFmAfuZaIaIwDjIa4ZJyuKCY')
// Geocode.setApiKey('AIzaSyAOhmxqhP-4lQ_QK_rfUdwnVgf_-OBO0eM')
Geocode.setApiKey('AIzaSyCELH0cxjmqfJj2Y5MpsCBaxpWlaK1olkc')
Geocode.enableDebug(true)

const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length

const resolveZipCode = zipCode => {
  return new Promise(function (resolve, reject) {
    if (zipCode && zipCode.match(/[0-9]{5}/g)) {
      Geocode.fromAddress(`${ zipCode }, USA`).then(
        response => {
          resolve(response.results[0].geometry.location)
        },
        error => {
          console.error(error)
          resolve(null)
        }
      )
    } else { resolve(null) }
  })
}

export const fetchCamps = async (options, isThrottled = false) => {
  let searchFilters = []

  let customDimensions = {}

  let searchParameters = { attributesToRetrieve: ['camp'], page: 0, hitsPerPage: isThrottled ? 12 : 1024 }

  if (options.term) {
    searchParameters.query = options.term
    customDimensions.dimension1 = options.term
  }

  const minAgeInt = parseInt(options.minAge)

  if (minAgeInt > 0) {
    searchFilters.push(`(minAge <= ${ minAgeInt })`)
  }

  const maxAgeInt = parseInt(options.maxAge)

  if (maxAgeInt > 0) {
    searchFilters.push(`(maxAge >= ${ maxAgeInt })`)
  }

  customDimensions.dimension2 = Math.round(average([minAgeInt, maxAgeInt]))

  const priceInt = parseInt(options.price)

  if (priceInt > 0) {
    searchFilters.push(`price <= ${ priceInt }`)
    customDimensions.dimension3 = priceInt
  }

  if (options.startDate != null) {
    searchFilters.push(`startDate >= ${ options.startDate }`)
    customDimensions.dimension4 = options.startDate
  } else {
    searchFilters.push(`startDate >= ${ moment().unix() }`)
  }

  if (options.endDate != null) {
    searchFilters.push(`endDate <= ${ options.endDate }`)
    customDimensions.dimension5 = options.endDate
  }

  searchParameters.filters = searchFilters.join(' AND ')

  if (options.zip != null) {
    customDimensions.dimension6 = options.zip
  }

  // ReactGA.ga('send', 'event', 'Search', 'Search', customDimensions)
  console.log(customDimensions);
  ReactGA.set({ ...customDimensions })

  ReactGA.event({
    category: 'Search',
    action: 'Search',
    ...customDimensions,
  })

  return resolveZipCode(options.zip)
    .then(result => {
      if (result !== null) {
        searchParameters.aroundLatLng = `${ result.lat }, ${ result.lng }`
        searchParameters.aroundRadius = 16093
      }
      return algoliaIndex.search(searchParameters)
        .then(result => {
          result.hits = result.hits.map(result => new CampIndex(result))
          return result
        })
    }).catch(err => console.error(err))
}
