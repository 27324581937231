export default class CampIndex {
  constructor(data) {
    // console.log(data)
    this.id = data.camp.id
    this.name = data.camp.name
    this.summary = data.camp.summary
    this.updatedAt = data._highlightResult.updatedAt
    this.snapshot = data.camp.snapshot
    this.image = data.camp.image
    this.organization = {
      id: data.camp.organization.id,
      name: data.camp.organization.name,
      accountTier: data.camp.organization.accountTier
    }
  }
}
