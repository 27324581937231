import { API, Auth, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currency } from '../components/SimSumHelpers';
import { debounce } from 'lodash';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchCamps } from '../components/fetchCamps';
import { listCampers } from '../graphql/queries';
import Layout from '../layouts/layout';
import MaskedInput from 'react-text-mask';
import Masonry from 'react-masonry-css';
import React from 'react';
import SEO from '../components/seo';
import SearchCampSummary from '../components/SearchCampSummary';
import awsmobile from '../aws-exports';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import moment from 'moment';
import qs from 'qs';
const { Camper } = require('../components/SimSumHelpers');

API.configure(awsmobile);

const _ = require('lodash');

const currencyMask = createNumberMask({
  prefix: '$',
});

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd');

class CamperDetails extends React.PureComponent {
  render() {
    return (
      <button
        type="button"
        onClick={() => this.props.toggleCamperCallback(this.props.camper)}
        className={`btn btn-sm mr-2 mb-2 ${
          this.props.isSelected ? `btn-light` : `btn-outline-light`
        }`}
      >
        {this.props.camper.name}
      </button>
    );
  }
}

class Search {
  constructor(parameters) {
    this.term = parameters.term || undefined;
    this.minAge = parameters.minAge || undefined;
    this.maxAge = parameters.maxAge || undefined;
    this.price = parameters.price || undefined;
    this.startDate = parameters.startDate || undefined;
    this.endDate = parameters.endDate || undefined;
    this.zip = parameters.zip || undefined;
  }

  queryString = () => {
    return qs.stringify(this);
  };

  isEmpty = () => {
    return (
      !this.term &&
      !this.minAge &&
      !this.maxAge &&
      !this.price &&
      !this.startDate &&
      !this.endDate &&
      !this.zip
    );
  };
}

/* eslint-disable no-unused-vars */
class ProtectedContentMessage extends React.PureComponent {
  render() {
    return (
      <div className="row grayBackground">
        <div className="offset-md-3 col-md-6 py-5 text-center">
          <div className="pb-3">
            Want to see <b>all</b> results? Create a free account! Accounts
            allow you to add campers and customize your search results so you
            can quickly build out your summer schedule.
          </div>
          <a href="/account/">
            <button type="button" className="btn btn-cta">
              Sign In or Sign Up
            </button>
          </a>
        </div>
      </div>
    );
  }
}

/* eslint-disable no-unused-vars */
class SearchSummary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = () => {};

  resultCount = (nbHits, page, nbPages, hitsPerPage) => {
    if (nbHits <= hitsPerPage) {
      return <>{nbHits}</>;
    } else {
      const first = 1 + page * hitsPerPage;
      const last = Math.min(nbHits, (page + 1) * hitsPerPage);
      return (
        <>
          <b>{first}</b> to <b>{last}</b> of <b>{nbHits}</b>
        </>
      );
    }
  };

  arrayToSentence = (arr) => {
    const last = arr.pop();
    return arr.length > 0 ? `${arr.join(', ')} and ${last}` : last;
  };

  render() {
    const searchTerm = ((_) => {
      return this.props.search.term ? (
        <>
          camps matching "
          <span onClick={this.handleClick} className="search-parameter">
            {this.props.search.term}
          </span>
          ",{' '}
        </>
      ) : (
        <>
          <span onClick={this.handleClick} className="search-parameter">
            all camps
          </span>
          ,{' '}
        </>
      );
    })();

    const searchAge = ((_) => {
      //
      return this.props.selectedCampers.length > 0 ? (
        <>
          for{' '}
          <span onClick={this.handleClick} className="search-parameter ">
            <u>
              {this.arrayToSentence(
                this.props.selectedCampers.map((camper) => camper.name)
              )}
            </u>
          </span>
          ,{' '}
        </>
      ) : (
        <>
          for{' '}
          <span onClick={this.handleClick} className="search-parameter">
            <u>any campers</u>
          </span>
          ,{' '}
        </>
      );
    })();

    const searchPrice = ((_) => {
      return this.props.search.price ? (
        <>
          costing{' '}
          <span onClick={this.handleClick} className="search-parameter">
            under {currency(this.props.search.price)}
          </span>
          ,{' '}
        </>
      ) : (
        <>
          at{' '}
          <span onClick={this.handleClick} className="search-parameter">
            any price
          </span>
          ,{' '}
        </>
      );
    })();

    const searchZip = ((_) => {
      return this.props.search.zip ? (
        <>
          within 10 miles of{' '}
          <span onClick={this.handleClick} className="search-parameter">
            {' '}
            {this.props.search.zip}
          </span>
          ,{' '}
        </>
      ) : (
        <>
          located{' '}
          <span onClick={this.handleClick} className="search-parameter">
            anywhere
          </span>
          ,{' '}
        </>
      );
    })();

    const searchDate = ((_) => {
      const format = 'dddd, MMMM Do';
      const startDate = this.props.search.startDate
        ? moment.unix(this.props.search.startDate)
        : null;
      const endDate = this.props.search.endDate
        ? moment.unix(this.props.search.endDate)
        : null;

      if (
        this.props.search.startDate &&
        startDate.isValid() &&
        this.props.search.endDate &&
        endDate.isValid()
      ) {
        return (
          <>
            operating between{' '}
            <span onClick={this.handleClick} className="search-parameter">
              {startDate.format(format)}
            </span>{' '}
            and{' '}
            <span onClick={this.handleClick} className="search-parameter">
              {endDate.format(format)}
            </span>
          </>
        );
      } else if (this.props.search.startDate && startDate.isValid()) {
        return (
          <>
            starting after{' '}
            <span onClick={this.handleClick} className="search-parameter">
              {startDate.format(format)}
            </span>{' '}
            and ending on{' '}
            <span onClick={this.handleClick} className="search-parameter">
              any date
            </span>
          </>
        );
      } else if (this.props.search.endDate && endDate.isValid()) {
        return (
          <>
            starting on{' '}
            <span onClick={this.handleClick} className="search-parameter">
              any date
            </span>{' '}
            and ending before{' '}
            <span onClick={this.handleClick} className="search-parameter">
              {endDate.format(format)}
            </span>
          </>
        );
      } else {
        return (
          <>
            <span onClick={this.handleClick} className="search-parameter">
              starting
            </span>{' '}
            or{' '}
            <span onClick={this.handleClick} className="search-parameter">
              ending
            </span>{' '}
            on any date
          </>
        );
      }
    })();

    let resultCount = this.resultCount(
      this.props.searchResults.nbHits,
      this.props.searchResults.page,
      this.props.searchResults.nbPages,
      this.props.searchResults.hitsPerPage
    );

    const searchPrefix = ((_) => {
      return this.props.searchStatus === 'searching' ||
        this.props.searchStatus === '' ? (
        'We are searching'
      ) : (
        <>Displaying {resultCount} results</>
      );
    })();

    return (
      <>
        {searchPrefix} for {searchTerm} {searchAge} {searchPrice} {searchZip}{' '}
        and {searchDate}.
      </>
    );
  }
}

/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
class SearchInterface extends React.PureComponent {
  runSearch = () => {
    const format = 'MM/DD';

    const startDateMoment = moment(this.state.searchStartDate, format).startOf(
      'day'
    );
    const startDateTimestamp =
      this.state.searchStartDate && startDateMoment.isValid()
        ? startDateMoment.unix()
        : null;

    const endDateMoment = moment(this.state.searchEndDate, format).endOf('day');
    const endDateTimestamp =
      this.state.searchEndDate && endDateMoment.isValid()
        ? endDateMoment.unix()
        : null;

    this.setState(
      {
        search: new Search({
          term: this.state.searchTerm,
          category: this.state.searchCategory,
          minAge: this.state.searchMinAge,
          maxAge: this.state.searchMaxAge,
          price: parseInt(this.state.searchPrice.replace(/\D/g, '')) * 100,
          startDate: startDateTimestamp,
          endDate: endDateTimestamp,
          zip: this.state.searchZip,
        }),
      },
      () => this.performSearch()
    );
  };

  performSearch = debounce(() => {
    this.setState({ searchResults: { hits: [] }, searchStatus: `searching` });

    fetchCamps(this.state.search, this.state.user === null)
      .then((response) =>
        this.setState({ searchResults: response, searchStatus: `complete` })
      )
      .catch((err) => {
        this.setState({ searchStatus: `error` });
        console.error(err);
      });
  }, 500);

  updateSearchStates = (search) => {
    const format = 'MM/DD';
    const startDate = moment.unix(search.startDate);
    const endDate = moment.unix(search.endDate);

    return {
      search: search,
      searchTerm: search.term || '',
      searchCategory: search.category || '',
      searchMinAge: search.minAge || '',
      searchMaxAge: search.maxAge || '',
      searchPrice: search.price >= 0 ? currency(search.price) : '',
      searchStartDate:
        search.startDate && startDate.isValid() ? startDate.format(format) : '',
      searchEndDate:
        search.endDate && endDate.isValid() ? endDate.format(format) : '',
      searchZip: search.zip || '',
    };
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.updateSearchStates({}),
      ...{
        isLoading: true,
        searchStatus: '',
        searchResults: { hits: [] },
        displaySearchInterface: false,
        user: null,
        advancedSearch: false,
        campers: [],
        selectedCampers: [],
        search: new Search({}),
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.toggleAdvanced = this.toggleAdvanced.bind(this);
  }

  clearSearch(event) {
    this.setState(this.updateSearchStates({}));
  }

  toggleAdvanced(event) {
    this.setState({ advancedSearch: !this.state.advancedSearch });
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({ displaySearchInterface: false }, () => this.runSearch());
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.runSearch()
    );
  }

  sortByAge = (lhs, rhs) => {
    lhs = lhs.age();
    rhs = rhs.age();
    return lhs === rhs ? 0 : lhs > rhs ? 1 : -1;
  };

  updateCampers = async () => {
    await API.graphql(graphqlOperation(listCampers, { limit: 1024 }))
      .then((response) => {
        const campers = response.data.listCampers.items
          .map((camper) => new Camper(camper))
          .sort(this.sortByAge);
        this.setState({ campers: campers, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ campers: [], isLoading: false });
      });
  };

  componentDidMount = async () => {
    await Auth.currentAuthenticatedUser()
      .then((response) => {
        this.setState({ user: response });
        this.updateCampers();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ user: null });
        // prompt login message
      });

    this.runSearch();
  };

  toggleCamper = (camper) => {
    const selectedCampers = _.xor(this.state.selectedCampers, [camper]);
    this.setState(
      {
        selectedCampers: selectedCampers,
        searchMinAge: Math.floor(
          _.min(selectedCampers.map((camper) => camper.age()))
        ),
        searchMaxAge: Math.ceil(
          _.max(selectedCampers.map((camper) => camper.age()))
        ),
      },
      () => this.runSearch()
    );
  };

  render() {
    // const searchResults = this.state.searchResults.hits
    //   .map((searchResult, index) => (<SearchCampSummary searchResult={searchResult} key={index} />))

    // TODO: Remove this console.log
    console.log(this.state.searchResults.hits);

    const resultsTier3 = this.state.searchResults.hits.filter(
      (camp) => camp.organization.accountTier === 3
    );
    const resultsTier3WithImage = resultsTier3.filter(
      (camp) => camp.image != null
    );
    const resultsTier3WithoutImage = resultsTier3.filter(
      (camp) => camp.image == null
    );

    const resultsTier3Ordered = resultsTier3WithImage.concat(
      resultsTier3WithoutImage
    );
    const resultsBelow3 = this.state.searchResults.hits.filter(
      (camp) => camp.organization.accountTier < 3
    );
    const resultsTier4 = this.state.searchResults.hits.filter(
      (camp) => camp.organization.accountTier == 4
    );

    const searchResults = resultsTier4
      .concat(resultsTier3Ordered)
      .concat(resultsBelow3)
      .map((searchResult, index) => (
        <SearchCampSummary searchResult={searchResult} key={index} />
      ));

    // const searchResults = resultsTier3Ordered.concat(resultsBelow3).concat(resultsTier4)
    //   .map((searchResult, index) => (<SearchCampSummary searchResult={searchResult} key={index} />))

    // TODO: Remove this console.log
    console.log('All Results: ', resultsTier3Ordered.concat(resultsBelow3));

    const searchClasses = this.state.advancedSearch
      ? 'd-inline'
      : 'd-none d-sm-inline';

    const protectedContentMessage = !this.state.user ? (
      <ProtectedContentMessage />
    ) : (
      ''
    );

    const campers = this.state.campers.map((camper, index) => (
      <CamperDetails
        key={index}
        camper={camper}
        toggleCamperCallback={this.toggleCamper}
        isSelected={_.includes(this.state.selectedCampers, camper)}
      />
    ));

    return (
      <>
        {/* Search Interface */}

        {/* Fill in as much or as little to narrow your search.  It's SIMPLE! */}

        <div className="container">
          <div id="searchHeader" className="row">
            <div id="searchBar" className="col-12 pt-3">
              <form onSubmit={this.onSubmit} autoComplete="off">
                <div className="container" id="searchInterface">
                  <div className={`row ${!this.state.user ? 'd-none' : ''}`}>
                    <div className="col-12">
                      <label htmlFor="searchTerm">
                        Please select at least one camper to customize search
                        results.
                      </label>
                      <div>
                        {this.state.isLoading ? (
                          <FontAwesomeIcon icon={faSyncAlt} spin />
                        ) : this.state.campers.length === 0 ? (
                          <span className="mb-2">
                            Adding campers helps create more relevant search
                            results. Add a camper from{' '}
                            <a href="/account/">your account page</a>.
                          </span>
                        ) : (
                          <>
                            {campers}
                            <a
                              href="/account/"
                              type="button"
                              className="btn btn-sm mr-2 mb-2 btn-primary`"
                            >
                              Add Camper
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <input type="submit" value="Submit" hidden />
                    <div className="col-12 col-sm-6 col-md-4 col-xl-3 pb-3 ">
                      <div className="form-group">
                        <label htmlFor="searchTerm">Search Term</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="searchTerm"
                          name="searchTerm"
                          placeholder="e.g. virtual, swimming, horses, cooking, computers"
                          value={this.state.searchTerm}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-6 col-md-4 col-xl-3 pb-3 ${searchClasses}`}
                    >
                      <div className="form-group">
                        <label htmlFor="searchPrice">under a price</label>
                        <MaskedInput
                          mask={currencyMask}
                          className="form-control form-control-sm"
                          id="searchPrice"
                          name="searchPrice"
                          placeholder="e.g. $100"
                          value={this.state.searchPrice}
                          onChange={this.handleChange}
                          data-inputmask="'alias': 'numeric', 'groupSeparator': ',', 'autoGroup': true, 'digits': 2, 'digitsOptional': false, 'prefix': '€ ', 'placeholder': '0'"
                        />
                      </div>
                    </div>
                    <div
                      className={`col-6 col-md-4 col-xl-3 pb-3 ${searchClasses}`}
                    >
                      <div className="form-group">
                        <label htmlFor="searchStartDate">
                          starting after date
                        </label>
                        <MaskedInput
                          mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                          pipe={autoCorrectedDatePipe}
                          className="form-control form-control-sm"
                          id="searchStartDate"
                          name="searchStartDate"
                          placeholder="MM/DD"
                          value={this.state.searchStartDate}
                          onChange={this.handleChange}
                          data-inputmask="'keepCharPositions': 'false'"
                        />
                      </div>
                    </div>
                    <div
                      className={`col-6 col-md-4 col-xl-3 pb-3 ${searchClasses}`}
                    >
                      <div className="form-group">
                        <label htmlFor="searchStartDate">
                          ending before date
                        </label>
                        <MaskedInput
                          mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                          pipe={autoCorrectedDatePipe}
                          className="form-control form-control-sm"
                          id="searchEndDate"
                          name="searchEndDate"
                          placeholder="MM/DD"
                          value={this.state.searchEndDate}
                          onChange={this.handleChange}
                          data-inputmask="'keepCharPositions': 'false'"
                        />
                      </div>
                    </div>
                    <div className="col-12 d-none">
                      <div className="form-group">
                        <label htmlFor="searchStartDate">drop-off before</label>
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/, /\d/]}
                          className="form-control form-control-sm"
                          id="searchStartTime"
                          name="searchStartTime"
                          placeholder="FPO FPO FPO FPO FPO FPO FPO FPO FPO FPO"
                          value={this.state.searchStartTime}
                          data-inputmask="'keepCharPositions': 'true'"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 d-none">
                      <div className="form-group">
                        <label htmlFor="searchStartDate">pick-up after</label>
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/, /\d/]}
                          className="form-control form-control-sm"
                          id="searchEndTime"
                          name="searchEndTime"
                          placeholder="FPO FPO FPO FPO FPO FPO FPO FPO FPO FPO"
                          value={this.state.searchEndTime}
                          data-inputmask="'keepCharPositions': 'true'"
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`col-6 col-md-4 col-xl-3 pb-3 ${searchClasses}`}
                    >
                      <div className="form-group">
                        <label htmlFor="searchStartDate">Zip Code</label>
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                          className="form-control form-control-sm"
                          id="searchZip"
                          name="searchZip"
                          placeholder="e.g. 95819"
                          value={this.state.searchZip}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="w-100 d-inline d-sm-none"></div>
                    <div className="col-4 col-xl-3 pb-3">
                      <label />
                      <button
                        onClick={this.onSubmit}
                        className={`btn btn-sm btn-block btn-primary`}
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-4 col-xl-3 pb-3 d-none">
                      <label />
                      <button
                        onClick={this.clearSearch}
                        className={`btn btn-sm btn-block btn-outline-light`}
                      >
                        Clear
                      </button>
                    </div>
                    <div className="col-4 col-xl-3 pb-3 d-inline d-sm-none">
                      <label />
                      <button
                        onClick={this.toggleAdvanced}
                        className={`btn btn-sm btn-block btn-outline-light`}
                      >
                        {this.state.advancedSearch ? 'Basic' : 'Advanced'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {/* Search Results */}
            <div className="col-12 p-3">
              <div className="font-italic text-center">
                <SearchSummary
                  search={this.state.search}
                  searchStatus={this.state.searchStatus}
                  searchResults={this.state.searchResults}
                  selectedCampers={this.state.selectedCampers}
                />
              </div>
            </div>
          </div>

          {protectedContentMessage}

          <div className="row">
            <div id="searchResults" className="col-12 px-3">
              <Masonry
                breakpointCols={{ default: 4, 1200: 3, 992: 2, 768: 1, 576: 1 }}
                className="simsum-masonry-grid"
                columnClassName="simsum-masonry-grid-column"
              >
                {searchResults}
              </Masonry>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const SearchPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Search" />
    <SearchInterface />
  </Layout>
);

export default SearchPage;
