import React from 'react'
import ribbon from '../images/ribbon.svg'
import slugify from 'slugify'
import moment from 'moment'

class SearchCampSummary extends React.PureComponent {
  render () {
    const searchResult = this.props.searchResult

    const accountTier = searchResult.organization.accountTier

    const cardClass = `camp card shadow-sm ss-${ accountTier }`

    const img = (searchResult.image) ? (accountTier === 4) ? (<><div className="ribbon"><img src={ ribbon } /></div><img className="img-fluid rounded mb-3 shadow-sm border" src={ searchResult.image } alt="camp detail imagery" /></>) : (accountTier > 1) ? (<><img className="img-fluid rounded mb-3 shadow-sm border" src={ searchResult.image } alt="camp detail imagery" /></>) : null : null

    const link = `/camp/${ searchResult.id }/${ slugify([searchResult.organization.name, searchResult.name].join(' '), { replacement: '-', remove: /[^A-Za-z0-9 ]/g, lower: true }) }/`

    const daysTillNotFresh = 7;

    let newBadge;
    if (searchResult.updatedAt != undefined) {
      const freshness = moment().diff(moment(searchResult.updatedAt.value), "days") <= daysTillNotFresh;
      newBadge = freshness ? <><p className="new-camp">Added or updated in the past {daysTillNotFresh} days</p></> : null;
    } else {
      newBadge = null;
    }

    return (
      <div className={cardClass} key={searchResult.id} id={searchResult.id}>
        <a href={ link }>
          <div className="card-body">
            { img }
            { newBadge }
            <h5>{searchResult.name}</h5>
            <h6>{searchResult.organization.name}</h6>
            <div>{ searchResult.summary }</div>
          </div>
          <div className="card-footer">
            <div className="small font-italic summary">{ searchResult.snapshot }</div>
          </div>
        </a>
      </div>
    )
  }
}

export default SearchCampSummary
